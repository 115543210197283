/* eslint-disable no-undef */
import Cookies from "js-cookie";

const getScript = (source, callback) => {
  const el = document.createElement("script");
  el.onload = callback;
  el.src = source;
  document.body.appendChild(el);
};

document.addEventListener("DOMContentLoaded", () => {
  // Get recaptcha cookie, if it doesn't exist don't execute this
  const recaptchaSiteKey = Cookies.get("recaptcha");
  if (recaptchaSiteKey === undefined) return;

  getScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`, function() {
    grecaptcha.ready(function() {
      // Hide recaptcha badge
      document.querySelector(".grecaptcha-badge").style.visibility = "collapse";

      // Fix for reserved namespace on buttons
      const submitButtons = document.querySelectorAll("input[name='submit'], button[name='submit']");
      submitButtons.forEach(button => {
        button.name = "submit_form";
      });

      // On form submit
      function recaptchaExecution(evt) {
        evt.preventDefault();
        const form = evt.currentTarget;
        if (evt.submitter) {
          addInput(form, {name: evt.submitter.name,
                          type: 'hidden',
                          value: evt.submitter.value})
        }
        // If not post don't get recaptcha
        if (form.method.toUpperCase() !== "POST") {
          form.removeEventListener("submit", recaptchaExecution);
          form.submit();
        } else {
          // Get recaptcha token and add to form before submitting
          grecaptcha.execute(recaptchaSiteKey).then(function(token) {
            addInput(form, {name: "recaptcha", type: 'hidden', value: token})
            form.removeEventListener("submit", recaptchaExecution);
            form.submit();
          });
        }
      }

      function addInput(form, attrs) {
        let el = document.createElement("input")
        Object.assign(el, attrs)
        form.appendChild(el)
      }

      const forms = document.querySelectorAll("form");
      forms.forEach(form => {
        form.addEventListener("submit", recaptchaExecution);
      });
    });
  });
});
